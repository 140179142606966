import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainPageService } from 'src/app/core/services/main-page.service';
import { StartupService } from 'src/app/core/services/startup.service';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {

  constructor(
    private _authService: AuthService,
    protected _router: Router,
    private _startupService: StartupService,
    private _mainPageService: MainPageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!next.data.skipLoginGuard) {
      return this.validate();
    }

    return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!next.data.skipLoginGuard) {
      return this.validate();
    }
    if (next.url[0].path === 'manager' && this._authService.isLoggedIn()) {
      const urlToRedirect = this._mainPageService.getReturnUrl();
      this._router.navigate([urlToRedirect]);
    }
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this._startupService.getSymplicityUrl() && !this._authService.isLoggedIn()) { // if portal has csm login
      window.open(this._startupService.getSymplicityUrl(), '_self');
      return false;
    }
    return true;
  }

  validate() {
    if (this._authService.isLoggedIn()) {

      const urlToRedirect = this._mainPageService.getReturnUrl();
      this._router.navigate([urlToRedirect]);
      return false;
    } else {
      if (this._authService.isTokenExpired() && this._authService.getCurrentToken()) {
        this._authService.logout();
      }
    }

    if (this._startupService.getSymplicityUrl()) { // if portal has csm login
      window.open(this._startupService.getSymplicityUrl(), '_self');
      return false;
    }

    return true;
  }
}
