import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { enumTranslatePublicUrlLegacy } from 'src/app/core/enums/enumTranslatePublicUrlLegacy';
import { enumTranslateRolesInRoutes } from 'src/app/core/enums/enumTranslateRolesInRoutes';
import { enumTranslateUrlLegacy } from 'src/app/core/enums/enumTranslateUrlLegacy';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainPageService } from 'src/app/core/services/main-page.service';
import { StartupService } from 'src/app/core/services/startup.service';
import { IframeRenderedService } from '../iframe-error/iframe-rendered.service';
import { UrlAuthRedirectService } from './url-auth-redirect.service';
import { catchError } from 'rxjs';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UrlAuthRedirectGuard {

  isLogin = false;
  constructor(
    private _startupService: StartupService,
    private _authService: AuthService,
    private _router: Router,
    private _urlAuthRedirectService: UrlAuthRedirectService,
    private _mainpageService: MainPageService,
    private _iframeRenderedService: IframeRenderedService) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (this._iframeRenderedService.isAppRenderedinsideIframe()) {
      // caso esteja em um iframe
      return this._router.navigate([`iframe-rendered`]);

    } else {

      if (next.queryParams.hasOwnProperty('credential')) {
        this._authService.initLogin.next(true);
        // if has the query param "credential" then make the login with it.
        this.isLogin = true;
        await this.loginWithCredential(next.queryParams.credential);
      }

      if (this._authService.isTokenExpired() && this._authService.getCurrentToken()) {
        this._authService.logout();
      }

      if (!next.url.length) {
        if (this._authService.isLoggedIn()) {
          // if next.url is empty but user is logged
          return this.navigateToRoleHome(next);
        } else {
          // if next.url is empty but user isn't logged
          return this.navigatePortalHome();
        }

      } else {

        return this.translateRouteAndNavigate(next, this._authService.isLoggedIn() ? this._authService.getUser() : null);
      }
    }
  }


  /**
   * navigate to home page based on logged user role.
   * @returns
   */
  public navigateToRoleHome(next?: ActivatedRouteSnapshot) {
    if (this.isLogin) {
      this.isLogin = false;
      return this.navigateToDefaultUrl();
    }

    if (this._startupService.hasLandingPage()) {
      if (!next.queryParams.hasOwnProperty('credential')) {
        window.open(this._startupService.getUrl(), '_self');
        return false;
      }

      return this.navigateToDefaultUrl();

    } else {

      if (this._startupService.getSymplicityUrl() || this._startupService.getUrl().toLowerCase().includes('login')) {
        return this.navigateToDefaultUrl();
      }

      return true;
    }
  }

  private navigateToDefaultUrl() {
    const urlToRedirect = this._mainpageService.getReturnUrl();
    return this._router.navigate([urlToRedirect]);
  }

  /**
   * Navigate to Portal Home. it could be:
   * landing page or
   * default page or
   * csm login
   * @returns
   */
  public navigatePortalHome() {
    // proceed to 'default page' or landing page or csm loggin
    if (this._startupService.hasLandingPage()) {

      window.open(this._startupService.getUrl(), '_self');
      return false;

    } else {

      if (!this._startupService.isPortalContratanet()) { // if portal isn't contratanet then proceed to the landing page (ex:Ulife)

        if (this._startupService.getSymplicityUrl()) { // if portal has csm login

          return this.navigatePortalLogin();
        } else {
          // if the url on startupservice is a login url. then redirect to spa login.
          if (this._startupService.getUrl().toLowerCase().includes('login')) {
            return this._router.navigate([`auth`]);
          }

          return true;
        }

      } else {
        return true;
      }

    }

  }

  /**
   * get the legacy route mapped on enumTranslateUrlLegacy;
   * if returns empty ('') then this route wasn't mapped on enumTranslateUrlLegacy.
   * @param url Array<UrlSegment>
   * @returns property of enumTranslateUrlLegacy or ''
   */
  private getMappedLegacyRoute(url: Array<UrlSegment>): string {
    let mappedLegacyRoute = '';
    const routeArr = url.map(a => a.path);

    while (routeArr.length && !mappedLegacyRoute) {
      let routeTest = routeArr.reduce((a, b) => `${a}/${b}`).toLowerCase();
      if (routeTest.includes('?')) { // remove possiveis query params para nao prejudicar o match de rotas legado
        routeTest = routeTest.substring(0, routeTest.indexOf('?'));
      }
      if (enumTranslateUrlLegacy.hasOwnProperty(routeTest) || enumTranslatePublicUrlLegacy.hasOwnProperty(routeTest)) {
        mappedLegacyRoute = routeTest;
      } else {
        routeArr.pop();
      }
    }
    return mappedLegacyRoute;
  }

  /**
   * check if url has a mapped legacy route on enumTranslateUrlLegacy
   * if it existes then transle it to Angular route and navigate
   * if it dosen't existes navigate to 'not found'
   * @param user
   * @returns
   */
  private translateRouteAndNavigate(next, user?) {
    const mappedLegacyRoute = this.getMappedLegacyRoute(next.url);
    if (!mappedLegacyRoute) {
      if (user) {
        return this.navigateToNotFound();
      }
      else {
        return this.navigatePortalLogin(next);
      }
    }
    else if (Object.keys(enumTranslatePublicUrlLegacy).includes(mappedLegacyRoute)) {
      const rota = enumTranslatePublicUrlLegacy[mappedLegacyRoute];
      // tratamento para o login.aspx

      const routeToNavigate = `${rota.includes('auth') ? '' : 'l/'}${rota}`;
      return this.navigateToRoute(routeToNavigate, next, this.getQueryParamsOnRoute(next));
    }
    else {
      if (user) {
        let routeToNavigate = `l/${enumTranslateRolesInRoutes[user.user_role]}/${enumTranslateUrlLegacy[mappedLegacyRoute]}`;
        routeToNavigate = this.routesWithParams(mappedLegacyRoute, next, routeToNavigate);
        // uma vez q tenho toda a rota traduzida, basta fazer a navegação passando os devitos paramentros

        if (next.queryParams['returnUrl']) {
          return this.navigateToRoute(next.queryParams['returnUrl'], next, this.getQueryParamsOnRoute(next))
        }


        return this.navigateToRoute(routeToNavigate, next, this.getQueryParamsOnRoute(next));
      }
      else {

        return this.navigatePortalLogin(next);
      }
    }
  }

  /**
   * função que retorna a rota do angular atualizado de acordo com uma rota do legado q possui parametros.
   * eu sei q a rota do legado estagio/documento possui o paramentro de identificacao do documento
   * ex: http://ulife.local.spa.com/estagio/documento/166a78c1-c107-4874-8e47-aedd0146b496
   * @param mappedLegacyRoute
   * @param next
   * @param routeToNavigate
   * @returns
   */
  routesWithParams(mappedLegacyRoute, next, routeToNavigate) {
    if (mappedLegacyRoute === 'estagio/documento') {
      routeToNavigate = `${routeToNavigate}/${next.url[2].path}`;
    } else if (mappedLegacyRoute === 'estagios/documentos') {
      routeToNavigate = `${routeToNavigate}/${next.url[2].path}/${next.url[3].path}/${next.url[4].path}/${next.url[5].path}`;
    } else if (mappedLegacyRoute === 'm/estagio/auditoria/documento') {
      routeToNavigate = `${routeToNavigate}/${next.url[2].path}`;
    } else if (mappedLegacyRoute === 'm/estagio/auditoria') {
      routeToNavigate = `${routeToNavigate}/${next.url[3].path}`;
    }

    return routeToNavigate;
  }

  /**
   * função que retorna um objeto de queyparams caso a rota do legado possua queryparams
   * @param next
   * @returns
   */
  getQueryParamsOnRoute(next) {
    const url = next.url.map(a => a.path).reduce((a, b) => a + '/' + b);
    let queryParams = null;
    if (url.includes('?') || Object.keys(next.queryParams).length) {
      //caso a url possui ? quer dizer q existe queryparams

      //removo da url a parte que representa os query params
      const queryParamString = url.substring(url.indexOf('?') + 1);

      // transformo os queryParams em objetos para o angular
      queryParams = Object.keys(next.queryParams).length ? next.queryParams :
        JSON.parse('{"' + queryParamString.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          (key, value) => key === '' ? value : decodeURIComponent(value));
    }

    return queryParams;
  }

  private savePreviousSrcUrl(next) {
    // save on _urlAuthRedirectService the legacy url
    let params = '';
    Object.keys(next.queryParams).filter((param) =>
      param !== 'credential')
      .forEach((key, index) => {
        params += `${index === 0 ? '?' : '&'}${key}=${next.queryParams[key]}`;
      });

    const url = next.url;
    const oldUrl = url.map(u => u.path).reduce((a, b) => a + '/' + b);

    if (oldUrl !== 'cadastro-login' && oldUrl !== 'Login.aspx') {

      this._urlAuthRedirectService.previousSrcUrl =
        oldUrl ? '/' + oldUrl + params + window.location.hash : '';
    }

  }

  private navigateToRoute(route, next, queryParams?) {
    this.savePreviousSrcUrl(next);
    if (queryParams) {
      return this._router.navigate([route], { queryParams: queryParams });
    } else {
      return this._router.navigate([route]);
    }
  }

  public navigatePortalLogin(next?) {
    // proceed to 'default page' or landing page or csm loggin
    const symplicityUrl = this._startupService.getSymplicityUrl();
    if (symplicityUrl == null || symplicityUrl === '') {
      if (next) {
        const queryParams = this.getQueryParamsOnRoute(next);
        return queryParams ? this._router.navigate(['/auth'], { queryParams: queryParams }) : this._router.navigate(['/auth'])
      } else {
        this._router.navigate(['/auth']);
      }
    } else {
      window.open(symplicityUrl, '_self');
    }
    return false;
  }

  /**
   * execute the login using the credential
   * @param credential
   */
  private async loginWithCredential(credential: string) {
    this._authService.logout();
    return await this._authService.signinWithCredential(credential)
      .pipe(catchError((error: HttpErrorResponse) => {
        debugger;
        if (error.status === HttpStatusCode.UnprocessableEntity) {
          this._authService.ValidateCampusAdminWithoutCampus(error);
        }else{
          this.navigatePortalLogin();
        }
        throw new Error(error.error);
      }))
      .toPromise();
  }


  /**
   * navigate to Not found page
   * @returns
   */
  private navigateToNotFound() {
    return this._router.navigate([`not-found`]);
  }
}
